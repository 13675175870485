export class VideoPrivacyHandler {
    videoContainers: NodeListOf<HTMLElement>;

    constructor() {
        this.videoContainers = document.querySelectorAll(".video");
    }

    registerEventListeners() {
        this.videoContainers.forEach((videoContainer) => {
            const videoPlaceholder = videoContainer.querySelector(".placeholder");
            const videoPrivacyNotice = videoContainer.querySelector(".video-caption");
            const videoFrameWrapper = videoContainer.querySelector(".video-frame-wrapper");
            
            if (videoFrameWrapper) {
                const closeButton= videoPrivacyNotice.querySelector(".video-caption-close-button");
                const videoFrame = videoFrameWrapper.querySelector("iframe");
                videoPrivacyNotice.classList.add("hidden");
                videoPlaceholder.addEventListener("click", () => {
                    if (videoPrivacyNotice.classList.contains("hidden")
                        && videoFrameWrapper.classList.contains("hidden")
                        && videoPrivacyNotice.classList.contains("legalHint")) {
                        videoPrivacyNotice.classList.remove("hidden");
                        closeButton.addEventListener("click",  () => {
                            
                            videoPrivacyNotice.classList.add("hidden");
                        });
                         
                    }
                    else {
                        videoPlaceholder.classList.add("hidden");
                        videoPrivacyNotice.classList.add("hidden");
                        videoFrame.setAttribute("src", videoFrame.dataset.src);
                        videoFrameWrapper.classList.remove("hidden")
                    }
                });
            }
        });
    }
     
}
