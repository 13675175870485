export class DownloadConfirmationHandler {
    downloadButtons: NodeListOf<HTMLElement>;

    constructor() {

    }

    registerEventListeners() {

        this.downloadButtons = document.querySelectorAll(".download-button");
        this.downloadButtons.forEach((downloadButton) => {
//            cconsole.log(downloadButton)

            const downloadConfirmationBackdrop: HTMLElement =
                downloadButton.nextElementSibling as HTMLElement;
            const downloadConfirmationForm: HTMLElement =
                downloadConfirmationBackdrop.querySelector(
                    ".download-confirmation-form"
                );
            const downloadConfirmationCloseButton =
                downloadConfirmationBackdrop.querySelector(
                    ".download-confirmation-close-button"
                );
            const downloadConfirmationDownloadButton =
                downloadConfirmationBackdrop.querySelector(
                    ".download-confirmation-download-button"
                );
            const downloadConfirmationLoadingSpinner =
                downloadConfirmationBackdrop.querySelector(".loading-spinner");


            downloadButton.addEventListener("click", () => {
                 
                this.openDownloadConfirmationPopup(
                    downloadConfirmationBackdrop
                );
            });
            downloadConfirmationCloseButton.addEventListener("click", () => {
                this.closeDownloadConfirmationPopup(
                    downloadConfirmationBackdrop
                );
            });
            downloadConfirmationDownloadButton.addEventListener("click", () => {
                downloadConfirmationLoadingSpinner.classList.remove('hidden');
                const timerInterval = window.setInterval(() => {
                    if (
                        document.cookie
                            .split(";")
                            .some((item) =>
                                item.trim().startsWith("fileDownload=")
                            )
                    ) {
                        document.cookie =
                            "fileDownload=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax";
                        this.closeDownloadConfirmationPopup(downloadConfirmationBackdrop
                        );
                        downloadConfirmationLoadingSpinner.classList.add('hidden');

                        window.clearInterval(timerInterval);
                    }
                }, 200);
            });
            downloadConfirmationBackdrop.addEventListener("click", () => {
                this.closeDownloadConfirmationPopup(downloadConfirmationBackdrop);
            });
            downloadConfirmationForm.addEventListener("click", (evt) => {
                evt.stopPropagation();
            });
        });
    }

    openDownloadConfirmationPopup(downloadConfirmationBackdrop: HTMLElement) {
        
        downloadConfirmationBackdrop.classList.remove("hidden");
    }
    closeDownloadConfirmationPopup(downloadConfirmationBackdrop: HTMLElement) {
        downloadConfirmationBackdrop.classList.add("hidden");
    }
}
