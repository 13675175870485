export class GallerySwipeHandler {
    currentItemIndex: Array<number>;
    galleryWrappers: NodeListOf<HTMLElement>;
    imageWrappers: Array<NodeListOf<HTMLElement>>;
    previousButtons: NodeListOf<HTMLElement>;
    nextButtons: NodeListOf<HTMLElement>;
    maxSwipeTime: number;
    minSwipeDistance: number;
    touchstartX: number;
    touchstartTime: number;
    swipeActiveThreshold: number;
    swipeIsActive: boolean;

    constructor() {
        this.currentItemIndex = [];
        this.galleryWrappers = document.querySelectorAll(
            ".images-wrapper.mobile-swipe"
        );
        this.imageWrappers = [];
        this.galleryWrappers.forEach((galleryWrapper, galleryWrapperIndex) => {
            this.currentItemIndex[galleryWrapperIndex] = 0;
            this.imageWrappers[galleryWrapperIndex] =
                galleryWrapper.querySelectorAll(".image-wrapper");
        });
        this.previousButtons = document.querySelectorAll(
            ".navigation-button-mobile.previous-item"
        );
        this.nextButtons = document.querySelectorAll(
            ".navigation-button-mobile.next-item"
        );
        this.maxSwipeTime = 250;
        this.minSwipeDistance = 100;
        this.swipeActiveThreshold = 576;
        this.updateSwipeIsActive();
    }

    registerEventListeners() {
        if (this.galleryWrappers.length !== 0) {
            this.previousButtons.forEach((previousButton, galleryIndex) => {
                previousButton.addEventListener("click", (evt) => {
                    evt.stopPropagation();
                    this.transitionToItem(
                        galleryIndex,
                        this.currentItemIndex[galleryIndex] - 1
                    );
                });
            });
            this.nextButtons.forEach((nextButton, galleryIndex) => {
                nextButton.addEventListener("click", (evt) => {
                    evt.stopPropagation();
                    this.transitionToItem(
                        galleryIndex,
                        this.currentItemIndex[galleryIndex] + 1
                    );
                });
            });
            window.addEventListener("resize", () => {
                this.updateSwipeIsActive();
                if (this.swipeIsActive) {
                    this.setItems(this.currentItemIndex);
                } else {
                    this.resetItems();
                }
            });
            this.galleryWrappers.forEach(
                (galleryWrapper, galleryWrapperIndex) => {
                    this.imageWrappers[galleryWrapperIndex].forEach(
                        (imageWrapper, imageWrapperIndex) => {
                            imageWrapper.addEventListener(
                                "touchstart",
                                (evt) => {
                                    if (this.swipeIsActive) {
                                        this.touchstartX =
                                            evt.changedTouches[0].screenX;
                                        this.touchstartTime = Date.now();
                                    }
                                }
                            );
                            imageWrapper.addEventListener("touchend", (evt) => {
                                if (this.swipeIsActive) {
                                    if (
                                        Date.now() - this.touchstartTime <
                                        this.maxSwipeTime
                                    ) {
                                        if (
                                            evt.changedTouches[0].screenX -
                                                this.touchstartX <
                                                -this.minSwipeDistance &&
                                            this.currentItemIndex[
                                                galleryWrapperIndex
                                            ] <
                                                this.imageWrappers[
                                                    galleryWrapperIndex
                                                ].length -
                                                    1
                                        ) {
                                            this.transitionToItem(
                                                galleryWrapperIndex,
                                                this.currentItemIndex[
                                                    galleryWrapperIndex
                                                ] + 1
                                            );
                                        } else if (
                                            evt.changedTouches[0].screenX -
                                                this.touchstartX >
                                                this.minSwipeDistance &&
                                            this.currentItemIndex[
                                                galleryWrapperIndex
                                            ] > 0
                                        ) {
                                            this.transitionToItem(
                                                galleryWrapperIndex,
                                                this.currentItemIndex[
                                                    galleryWrapperIndex
                                                ] - 1
                                            );
                                        }
                                    }
                                    this.touchstartX = 0;
                                    this.touchstartTime = 0;
                                }
                            });
                        }
                    );
                }
            );
        }
    }

    setItems(itemIndex: Array<number>) {
        this.galleryWrappers.forEach((galleryWrapper, galleryWrapperIndex) => {
            this.currentItemIndex[galleryWrapperIndex] =
                itemIndex[galleryWrapperIndex];
            this.showOrHideNavigationButtons();
            this.disableSlidingAnimation();
            window.setTimeout(() => {
                galleryWrapper.style.transform = `translateX(-${
                    this.currentItemIndex[galleryWrapperIndex] *
                    galleryWrapper.clientWidth
                }px)`;
                window.setTimeout(() => {
                    this.enableSlidinganimation();
                }, 10);
            }, 10);
        });
    }

    resetItems() {
        this.galleryWrappers.forEach((galleryWrapper, galleryWrapperIndex) => {
            this.currentItemIndex[galleryWrapperIndex] = 0;
            this.showOrHideNavigationButtons();
            this.disableSlidingAnimation();
            window.setTimeout(() => {
                galleryWrapper.style.transform = `translateX(0)`;
                window.setTimeout(() => {
                    this.enableSlidinganimation();
                }, 10);
            }, 10);
        });
    }

    transitionToItem(galleryIndex: number, itemIndex: number) {
        this.currentItemIndex[galleryIndex] = itemIndex;
        this.showOrHideNavigationButtons();
        this.galleryWrappers[galleryIndex].style.transform = `translateX(-${
            this.currentItemIndex[galleryIndex] *
            this.galleryWrappers[galleryIndex].clientWidth
        }px)`;
    }

    showOrHideNavigationButtons() {
        this.currentItemIndex.forEach((itemIndex, galleryIndex) => {
            if (itemIndex == 0) {
                this.hidePreviousButton(galleryIndex);
            } else {
                this.showPreviousButton(galleryIndex);
            }
            if (itemIndex == this.imageWrappers[galleryIndex].length - 1) {
                this.hideNextButton(galleryIndex);
            } else {
                this.showNextButton(galleryIndex);
            }
        });
    }

    showPreviousButton(galleryIndex) {
        this.previousButtons.forEach((previousButton, previousButtonIndex) => {
            if (previousButtonIndex == galleryIndex) {
                previousButton.className = previousButton.className
                    .replace("hidden", "")
                    .trim();
            }
        });
    }

    hidePreviousButton(galleryIndex) {
        this.previousButtons.forEach((previousButton, previousButtonIndex) => {
            if (previousButtonIndex == galleryIndex) {
                if (!previousButton.className.includes("hidden")) {
                    previousButton.className += " hidden";
                }
            }
        });
    }

    showNextButton(galleryIndex) {
        this.nextButtons.forEach((nextButton, nextButtonIndex) => {
            if (nextButtonIndex == galleryIndex) {
                nextButton.className = nextButton.className
                    .replace("hidden", "")
                    .trim();
            }
        });
    }

    hideNextButton(galleryIndex) {
        this.nextButtons.forEach((nextButton, nextButtonIndex) => {
            if (nextButtonIndex == galleryIndex) {
                if (!nextButton.className.includes("hidden")) {
                    nextButton.className += " hidden";
                }
            }
        });
    }

    disableSlidingAnimation() {
        this.galleryWrappers.forEach((galleryWrapper) => {
            galleryWrapper.className = galleryWrapper.className
                .replace("transitioning", "")
                .trim();
        });
    }

    enableSlidinganimation() {
        this.galleryWrappers.forEach((galleryWrapper) => {
            if (!galleryWrapper.className.includes("transitioning")) {
                galleryWrapper.className += " transitioning";
            }
        });
    }

    updateSwipeIsActive() {
        if (window.innerWidth < this.swipeActiveThreshold) {
            this.swipeIsActive = true;
        } else {
            this.swipeIsActive = false;
        }
        return;
    }
}
