export class SidebarGalleryHandler {
    sidebarGalleryWrapper: HTMLElement;
    sidebarGallery: HTMLElement;
    sidebarGalleryImages: NodeListOf<HTMLElement>;
    sidebarGalleryButton: HTMLElement;
    animationStartTimestamp: number;
    galleryMovementSpeed: number;
    elapsedAnimationTime: number;
    galleryIsOpen: boolean;
    nextImageIndexToLoad: number;
    nextImageIndexToClone: number;
    requestAnimationFrameID: number;
    isMoving: boolean = false;
    pauseBeginTime: number = 0;
    docClickListener: EventListener;

    constructor() {
        this.sidebarGalleryWrapper = document.querySelector(
            ".sidebar-gallery-wrapper"
        );
        this.sidebarGallery =
            this.sidebarGalleryWrapper.querySelector(".sidebar-gallery");
        this.sidebarGalleryImages = this.sidebarGallery.querySelectorAll("img");
        this.sidebarGalleryButton = this.sidebarGalleryWrapper.querySelector(
            ".sidebar-gallery-button"
        );
        this.galleryMovementSpeed = 0.1;
        this.elapsedAnimationTime = 0;
        this.galleryIsOpen = false;
        this.nextImageIndexToLoad = 0;
        this.nextImageIndexToClone = 1;
        this.docClickListener = this.closeSidebarGallery.bind(this);
    }

    registerEventListeners() {
        this.sidebarGalleryButton.addEventListener("click", (evt) => {
            evt.stopPropagation();
            this.toggleSidebarGalleryOpened();
        });
        this.sidebarGalleryWrapper.addEventListener("mouseover", (evt) => {
            this.pauseSidebar();
        })
        this.sidebarGalleryWrapper.addEventListener("mouseout", (evt) => {
            this.resumeSidebar();
        })
    }

    toggleSidebarGalleryOpened() {
        if (this.sidebarGalleryWrapper.className.includes("opened")) {
            this.closeSidebarGallery();
        } else {
            this.openSidebarGallery();
        }
    }

    closeSidebarGallery() {
        document.removeEventListener("click", this.docClickListener, false);
        if (this.sidebarGalleryWrapper.classList.contains("opened")) {
            this.sidebarGalleryWrapper.classList.remove("opened")
            this.galleryIsOpen = false;
            window.setTimeout(() => {
                this.elapsedAnimationTime +=
                    Date.now() - this.animationStartTimestamp - 250;
            }, 250);
            this.isMoving = false;
        }
    }

    openSidebarGallery() {
        document.addEventListener("click", this.docClickListener, false);
        this.sidebarGalleryWrapper.classList.add("opened")
        this.galleryIsOpen = true;
        this.animateSidebar();
    }

    animateSidebar() {

        this.animationStartTimestamp = Date.now();
        this.isMoving = true;
        this.requestAnimationFrameID = window.requestAnimationFrame(this.moveGallery.bind(this));
        //       
    }


    pauseSidebar() {
        this.isMoving = false;
        this.pauseBeginTime = Date.now()
    }

    resumeSidebar() {
        this.isMoving = true;

        this.animationStartTimestamp += Date.now() - this.pauseBeginTime
    }
 

    moveGallery() {
        const elapsed =
            Date.now() -
            this.animationStartTimestamp +
            this.elapsedAnimationTime;
        if (this.isMoving)
            this.sidebarGallery.style.transform = `translateY(-${elapsed * this.galleryMovementSpeed
                }px)`;
        this.lazyLoadNextImage();
        this.cloneTopmostImage();
        if (this.galleryIsOpen) {
            this.requestAnimationFrameID = window.requestAnimationFrame(this.moveGallery.bind(this));

        }
    }

    lazyLoadNextImage() {
        if (this.nextImageIndexToLoad >= this.sidebarGalleryImages.length - 1) {
            return;
        }
        const nextImageToLoad = this.sidebarGalleryImages.item(
            this.nextImageIndexToLoad
        );
        if (
            nextImageToLoad &&
            nextImageToLoad.getBoundingClientRect().top <
            1.5 * window.innerHeight
        ) {
            nextImageToLoad.setAttribute(
                "src",
                nextImageToLoad.getAttribute("data-src")
            );
            this.nextImageIndexToLoad++;
        }
    }

    cloneTopmostImage() {
        const nextImageToClone = this.sidebarGallery.querySelector(
            "a:nth-child(" + this.nextImageIndexToClone + ")"
        );
        if (
            nextImageToClone &&
            nextImageToClone.getBoundingClientRect().bottom < 0
        ) {
            this.sidebarGallery.appendChild(nextImageToClone.cloneNode(true));
            this.nextImageIndexToClone++;
        }
    }
}
