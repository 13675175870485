export class QuotationsSliderHandler {
    transitionTime: number;
    quotationsSlider: HTMLElement;
    quotationSlidesWrapper: HTMLElement;
    quotationSlides: NodeListOf<HTMLElement>;
    quotationsSlideDurations: Array<number>;
    currentQuotationsSlideIndex: number;
    nextQuotationsSlideIndex: number;
    previousQuotationsSlideIndex: number;
    currentQuotationsSlideTransitionTimeout: number;
    nextQuotationsSlideButton: HTMLElement;
    id: string;

    constructor(id: string) {
        this.id = id;
        if (document.querySelector("#" + this.id) !== undefined) {
            this.transitionTime = 500;
            this.quotationsSlider =
                document.querySelector("#" + this.id);
            this.quotationSlidesWrapper = this.quotationsSlider.querySelector(".slides");
            this.quotationSlides = this.quotationSlidesWrapper.querySelectorAll(".slide");
            this.quotationsSlideDurations = [];
            this.quotationSlides.forEach((slide) => {
                this.quotationsSlideDurations.push(parseInt(slide.dataset.duration)
                );
            });
            this.nextQuotationsSlideButton =
                this.quotationsSlider.querySelector(".next-slide-button");
            this.currentQuotationsSlideIndex = 0;
            this.nextQuotationsSlideIndex = 1;
            this.previousQuotationsSlideIndex = this.quotationSlides.length - 1;
            if (this.quotationSlides.length > 1) {
                this.startAutomaticQuotationsSlideChange();
            }


        }
    }

    registerEventListeners() {
        if (document.querySelector("#" + this.id) !== undefined) {
            this.nextQuotationsSlideButton.addEventListener("click", () => {
                this.stopAutomaticQuotationsSlideChange();
                this.relocateQuotationsSlide(
                    this.previousQuotationsSlideIndex,
                    "right"
                );
                this.currentQuotationsSlideIndex =
                    this.nextQuotationsSlideIndex;
                this.startAutomaticQuotationsSlideChange();
            });
        }
    }
    slideChangesCount = 0;
    startAutomaticQuotationsSlideChange() {
        this.previousQuotationsSlideIndex =
            this.currentQuotationsSlideIndex - 1;
        if (this.previousQuotationsSlideIndex < 0) {
            this.previousQuotationsSlideIndex = this.quotationSlides.length - 1;
        }
        this.nextQuotationsSlideIndex = this.currentQuotationsSlideIndex + 1;
        if (this.nextQuotationsSlideIndex >= this.quotationSlides.length) {
            this.nextQuotationsSlideIndex = 0;
        }
        this.quotationSlides.item(this.previousQuotationsSlideIndex).style.transform = "translateX(-100%)";
        this.quotationSlides.item(this.currentQuotationsSlideIndex).style.transform = "translateX(0)";
        this.nextQuotationsSlideButton.style.transform = "rotate(" + this.slideChangesCount++ * -90 + "deg)";

        this.currentQuotationsSlideTransitionTimeout = window.setTimeout(() => {
            this.relocateQuotationsSlide(
                this.previousQuotationsSlideIndex,
                "right"
            );
            this.currentQuotationsSlideIndex = this.nextQuotationsSlideIndex;
            this.startAutomaticQuotationsSlideChange();
        }, this.quotationsSlideDurations[this.currentQuotationsSlideIndex] * 1000);
    }

    stopAutomaticQuotationsSlideChange() {
        window.clearTimeout(this.currentQuotationsSlideTransitionTimeout);
    }

    relocateQuotationsSlide(
        index: number,
        direction: "left" | "center" | "right"
    ) {
        if (direction === "left") {
            this.quotationSlides.item(index).style.display = "none";
            this.quotationSlides.item(index).style.transform =
                "translateX(-100%)";
            window.setTimeout(() => {
                this.quotationSlides.item(index).style.display = "flex";
            }, 1.5 * this.transitionTime);
        } else if (direction === "center") {
            this.quotationSlides.item(index).style.display = "none";
            this.quotationSlides.item(index).style.transform = "translateX(0)";
            window.setTimeout(() => {
                this.quotationSlides.item(index).style.display = "flex";
            }, 1.5 * this.transitionTime);
        } else if (direction === "right") {
            this.quotationSlides.item(index).style.display = "none";
            this.quotationSlides.item(index).style.transform =
                "translateX(100%)";
            window.setTimeout(() => {
                this.quotationSlides.item(index).style.display = "flex";
            }, 1.5 * this.transitionTime);
        }
    }
}
