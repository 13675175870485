import {NavigationHandler} from "./classes/NavigationHandler";
import {LanguageSelectHandler} from "./classes/LanguageSelectHandler";
import {TopbarHandler} from "./classes/TopbarHandler";
import {SidebarGalleryHandler} from "./classes/SidebarGalleryHandler";
//import { DownloadConfirmationHandler } from "./classes/DownloadConfirmationHandler";
import {LightboxHandler} from "./classes/LightboxHandler";
import {GallerySwipeHandler} from "./classes/GallerySwipeHandler";
import {FaqHandler} from "./classes/FaqHandler";
import {Toc} from "./classes/Toc";
import {GlossaryHandler} from "./classes/GlossaryHandler";

import {QuotationsSliderHandler} from "./classes/QuotationsSliderHandler";
import {LocalVideoLoopHandler} from "./classes/LocalVideoLoopHandler";
import { VideoPrivacyHandler } from "./classes/VideoPrivacyHandler";
 
    




import {NavSearchHandler} from "./classes/NavSearchHandler";

declare global {
    interface Window {quotationssliders: any;}
}

document.addEventListener("DOMContentLoaded", () => {
    if (!String.prototype.includes) {
        String.prototype.includes = function (search: any, start: number) {
            "use strict";

            if (search instanceof RegExp) {
                throw TypeError("first argument must not be a RegExp");
            }
            if (start === undefined) {
                start = 0;
            }
            return this.indexOf(search, start) !== -1;
        };
    }

    let navigationHandler: NavigationHandler = new NavigationHandler();
    let languageSelectHandler: LanguageSelectHandler =
        new LanguageSelectHandler();
    let topbarHandler: TopbarHandler = new TopbarHandler();
    let sidebarGalleryHandler: SidebarGalleryHandler =
        new SidebarGalleryHandler();
    //    let downloadConfirmationHandler: DownloadConfirmationHandler =
    //        new DownloadConfirmationHandler();
    let lightboxHandler: LightboxHandler = new LightboxHandler();
    let gallerySwipeHandler: GallerySwipeHandler = new GallerySwipeHandler();
    let faqHandler: FaqHandler = new FaqHandler();
    let toc: Toc = new Toc();
    let navSearchHandler = new NavSearchHandler();
    let glossaryHandler: GlossaryHandler = new GlossaryHandler();
    let localVideoLoopHandler: LocalVideoLoopHandler = new LocalVideoLoopHandler();
let videoPrivacyHandler: VideoPrivacyHandler = new VideoPrivacyHandler();

    navigationHandler.registerEventListeners();
    languageSelectHandler.registerEventListeners();
    topbarHandler.registerEventListeners();
    sidebarGalleryHandler.registerEventListeners();
    //    downloadConfirmationHandler.registerEventListeners();
    lightboxHandler.registerEventListeners();
    gallerySwipeHandler.registerEventListeners();
    faqHandler.registerEventListeners();
    toc.registerEventListeners();
    navSearchHandler.registerEventListeners();
    glossaryHandler.registerEventListeners();
    localVideoLoopHandler.registerEventListeners();
        videoPrivacyHandler.registerEventListeners();


    window.quotationssliders = window.quotationssliders || {};
    if (window.quotationssliders.quotationSliderIds == undefined) {return };
    for (let i = 0; i < window.quotationssliders.quotationSliderIds.length; i++) {
        var quotationsSliderHandler: QuotationsSliderHandler = new QuotationsSliderHandler(window.quotationssliders.quotationSliderIds[i]);
        quotationsSliderHandler.registerEventListeners();
    }







});
