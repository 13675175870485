import {DownloadConfirmationHandler} from "../classes/DownloadConfirmationHandler";

export class LightboxHandler {

    lightbox: HTMLElement;
    lightboxItemsWrapper: HTMLElement;
    lightboxGroups: NodeListOf<HTMLElement>;
    lightboxItems: Array<Array<HTMLElement>>;
    previousButton: HTMLElement;
    nextButton: HTMLElement;
    closeButton: HTMLElement;
    currentGroupIndex: number;
    currentItemIndex: number;
    maxSwipeTime: number;
    minSwipeDistance: number;
    touchstartX: number;
    touchstartTime: number;
    hasLightboxImages: NodeListOf<HTMLElement>;
    downloadConfirmationHandler: DownloadConfirmationHandler;
    theLightboxIamgeWrapper: HTMLElement;

    constructor() {
        this.lightbox = document.querySelector(".lightbox");
        this.lightboxItemsWrapper = this.lightbox.querySelector(
            ".lightbox-content .lightbox-items-wrapper"
        );



        this.hasLightboxImages = document.querySelectorAll(".has-lightbox");
        this.lightboxItems = [];
        for (var i = 0; i < 101; i++) {
            this.lightboxItems[i] = [];

        }
        this.hasLightboxImages.forEach((image: HTMLElement, index) => {
            var groupIndex = parseInt(image.dataset.groupIndex);

            if (Number.isNaN(groupIndex)) groupIndex = 0;

            this.lightboxItems[groupIndex].push(image);
//            console.log("BUILD: " + groupIndex + ": " + this.lightboxItems[groupIndex].length)
        });

        this.previousButton = this.lightbox.querySelector(
            ".navigation-button.previous-item"
        );
        this.nextButton = this.lightbox.querySelector(
            ".navigation-button.next-item"
        );


        this.closeButton = this.lightbox.querySelector(".close-lightbox");
        this.currentGroupIndex = 0;
        this.currentItemIndex = 0;
        this.maxSwipeTime = 100;
        this.minSwipeDistance = 30;
    }

    registerEventListeners() {
        this.downloadConfirmationHandler = new DownloadConfirmationHandler();
        if (this.lightboxItems.length !== 0) {

            for (var i = 0; i < this.lightboxItems.length; i++) {
                if (this.lightboxItems[i].length === 0) continue;
                var group = this.lightboxItems[i];
                if (group === undefined) continue;
                group.forEach((item, itemIndex) => {

                    item.parentNode.parentElement.querySelector(".image-counter").innerHTML = (itemIndex + 1) + "/" + group.length;
                    item.addEventListener(
                        "click",
                        () => {
                            this.currentGroupIndex = parseInt(item.dataset.groupIndex);
//                            console.log(this.currentGroupIndex + "(" + parseInt(item.dataset.groupIndex) + "): " + this.lightboxItems[this.currentGroupIndex].length + " cl:" + itemIndex)

                            this.openLightbox(itemIndex);
                        }
                    );
                });
            };
            this.lightbox.addEventListener("click", () => {
                this.hideLightbox();
            });
            this.lightboxItemsWrapper.addEventListener("click", (evt) => {
                evt.stopPropagation();
            });
            this.closeButton.addEventListener("click", () => {
                this.hideLightbox();
            });
            this.previousButton.addEventListener("click", (evt) => {
                evt.stopPropagation();
                this.transitionToLightboxItem(this.currentItemIndex - 1);
                this.closeDownloadConfirmationPopups();
            });
            this.nextButton.addEventListener("click", (evt) => {
                evt.stopPropagation();
                this.transitionToLightboxItem(this.currentItemIndex + 1);
                this.closeDownloadConfirmationPopups();

            });
            window.addEventListener("resize", () => {
                this.fixDownloadConfirmationPopupPositions();
                this.setLightboxItem(this.currentItemIndex);
            });
            this.lightbox.addEventListener("touchstart", (evt) => {
                this.touchstartX = evt.changedTouches[0].screenX;
                this.touchstartTime = Date.now();
            });
            this.lightbox.addEventListener("touchend", (evt) => {
                if (Date.now() - this.touchstartTime < this.maxSwipeTime) {
                    if (
                        evt.changedTouches[0].screenX - this.touchstartX <
                        -this.minSwipeDistance &&
                        this.currentItemIndex <
                        this.lightboxItems[this.currentGroupIndex].length -
                        1
                    ) {
                        this.transitionToLightboxItem(
                            this.currentItemIndex + 1
                        );
                    } else if (
                        evt.changedTouches[0].screenX - this.touchstartX >
                        this.minSwipeDistance &&
                        this.currentItemIndex > 0
                    ) {
                        this.transitionToLightboxItem(
                            this.currentItemIndex - 1
                        );
                    }
                }
                this.touchstartX = 0;
                this.touchstartTime = 0;
            });
        }
    }

    openLightbox(initialItemIndex: number) {

        this.clearLightbox();
        var initialGroup = this.lightboxItems[this.currentGroupIndex];
        this.fillLightbox(initialGroup);

        this.setLightboxItem(initialItemIndex);
        this.showLightbox();
        //        
        this.downloadConfirmationHandler.registerEventListeners();
        window.setTimeout(() => {
            this.fixDownloadConfirmationPopupPositions();
        }, 40);
    }

    clearLightbox() {
        while (this.lightboxItemsWrapper.firstChild) {
            this.lightboxItemsWrapper.firstChild.remove();
        }
    }

    fillLightbox(group: Array<HTMLElement>) {
        group.forEach((image: HTMLElement, ind) => {

            const lightboxImage = image.parentElement.querySelector(".lightbox-image img");
            //            if (lightboxImage !== null) {
            lightboxImage.setAttribute("srcset", lightboxImage.getAttribute("data-srcset"));
            lightboxImage.setAttribute("sizes", lightboxImage.getAttribute("data-sizes"));
            lightboxImage.setAttribute("src", lightboxImage.getAttribute("data-src"));
//            console.log(ind + ": " + lightboxImage.getAttribute("src"));
            let lightboxImageWrapper = image.parentNode.querySelector(".lightbox-item");
            this.lightboxItemsWrapper.appendChild(lightboxImageWrapper.cloneNode(true));
            //            }
        });
    }



    transitionToLightboxItem(itemIndex: number) {
        this.currentItemIndex = itemIndex;
        if (this.currentItemIndex === this.lightboxItems[this.currentGroupIndex].length) {
            this.currentItemIndex = 0;
        } else if (this.currentItemIndex === -1) {
            this.currentItemIndex = this.lightboxItems[this.currentGroupIndex].length - 1;
        }
        this.showOrHideNavigationButtons();
        this.lightboxItemsWrapper.style.transform = `translateX(-${this.currentItemIndex * this.lightboxItemsWrapper.clientWidth}px)`;
    }
    setLightboxItem(itemIndex: number) {
        this.currentItemIndex = itemIndex;
        this.showOrHideNavigationButtons();
        this.transitionToLightboxItem(itemIndex);
        this.enableSlidinganimation()

        //        this.disableSlidingAnimation();
        //        window.setTimeout(() => {
        //            this.lightboxItemsWrapper.style.transform = `translateX(-${this.currentItemIndex * this.lightboxItemsWrapper.clientWidth}px)`;
        //            window.setTimeout(() => {
        //                this.enableSlidinganimation();
        //            }, 10);
        //        }, 10);
    }

    showLightbox() {
        this.lightbox.classList.remove("hidden");
    }

    hideLightbox() {
        this.lightbox.classList.add("hidden");
    }

    showOrHideNavigationButtons() {
       
        if (this.lightboxItemsWrapper.children.length===1){
            
            this.hidePreviousButton();
            this.hideNextButton();
        }
        //        if (this.currentItemIndex == 0) {
        //            this.hidePreviousButton();
        //        } else {
        //            this.showPreviousButton();
        //        }
        //        if (
        //            this.currentItemIndex ==
        //            this.lightboxItems[this.currentGroupIndex].length - 1
        //        ) {
        //            this.hideNextButton();
        //        } else {
        //            this.showNextButton();
        //        }
    }

    showPreviousButton() {
        this.previousButton.classList.remove("hidden");

    }

    hidePreviousButton() {
        this.previousButton.classList.add("hidden");

    }

    showNextButton() {
        this.nextButton.classList.remove("hidden");

    }

    hideNextButton() {
        this.nextButton.classList.add("hidden");
    }

    disableSlidingAnimation() {
        this.lightboxItemsWrapper.classList.remove("transitioning");
    }

    enableSlidinganimation() {
        this.lightboxItemsWrapper.classList.add("transitioning");
    }

    fixDownloadConfirmationPopupPositions() {
        var children = this.lightboxItemsWrapper.children;
 
            for (var wrapperIndex = 0; wrapperIndex < children.length; wrapperIndex++) {
                var itemWrapper = children[wrapperIndex];
                // Do stuff
            
            const popups: NodeListOf<HTMLElement> = itemWrapper.querySelectorAll(
                ".download-confirmation-backdrop");
            popups.forEach((popup, itemIndex) => {
//                console.log(this + " fixpos " + wrapperIndex)
                popup.style.left = wrapperIndex * this.lightboxItemsWrapper.clientWidth + "px";

            });
        } 
    }
    closeDownloadConfirmationPopups() {

        const popups: NodeListOf<HTMLElement> = this.lightboxItemsWrapper.querySelectorAll(
            ".download-confirmation-backdrop");
        popups.forEach((popup, itemIndex) => {
            popup.classList.add("hidden");

        });

    }
}
