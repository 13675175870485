 export class LocalVideoLoopHandler {
    localVideoContainers: NodeListOf<HTMLElement>;
    loopingVideos: Array<HTMLVideoElement>;

    constructor() {
        this.localVideoContainers = document.querySelectorAll(".local-video");
    }

    registerEventListeners() {

        if (this.localVideoContainers === undefined) return false;

        this.loopingVideos = [];
        this.localVideoContainers.forEach((videoContainer) => {
            let video: HTMLVideoElement = videoContainer.querySelector("video");
            if (video.getAttribute("loop") === "true") {
                this.loopingVideos.push(video);
            }
        });

        const onScrollStop = callback => {
            let isScrolling;
            window.addEventListener(
                'scroll',
                function (e: MouseEvent) {
                    window.clearTimeout(isScrolling);
                    isScrolling = setTimeout(() => {

                        callback();
                    }, 150);
                },
                false
            );
        };

        onScrollStop(() => {
            this.updateVideoVisibility();
        });
        this.updateVideoVisibility();
    }


    updateVideoVisibility() {
        for (var i = 0; i < this.loopingVideos.length; i++) {

            var v = this.loopingVideos[i];
//            console.log(i, v.paused)
            if (this.isElementInViewport(v)) {
                if (v.paused) v.play();
            } else {
                if (v.played) v.pause();
            }
//            console.log(i, "?", v.paused)
        }
    }
    isElementInViewport(element: HTMLElement) {
        var rect: DOMRect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}