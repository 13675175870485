
 
export class NavSearchHandler {
    nav: HTMLElement;
    navSearchWrapper: HTMLElement;
    navSearchButton: HTMLElement;
    navSearchInput: HTMLElement;
    

    constructor() {
        this.nav = document.querySelector(".site-navigation-inner");
        this.navSearchWrapper = this.nav.querySelector(".nav-search");
        this.navSearchButton =
            this.navSearchWrapper.querySelector(".search-button");
        this.navSearchInput = this.navSearchWrapper.querySelector(
            ".search-input-wrapper input"
        );
    }
    registerEventListeners() {
        this.navSearchButton.addEventListener("click", (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            this.toggleNavBarSearchOpened();
        });
        this.navSearchInput.addEventListener("click", (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
        });
         
    }
    toggleNavBarSearchOpened() {
        if (this.navSearchWrapper.className.includes("opened")) {
            this.closeNavSearch();
        } else {
            this.openNavSearch();
        }
    }
    openNavSearch() {
        if (!this.navSearchWrapper.className.includes("opened")) {
            this.navSearchWrapper.className += " opened";
        }
        this.navSearchInput.focus();
       
    }
    public closeNavSearch() {
        this.navSearchWrapper.className = this.navSearchWrapper.className
            .replace("opened", "")
            .trim();
    }
}
