/*
 * AL
 */

export class FaqHandler {
    detailsOpenCloseButtons: NodeListOf<HTMLElement>;;

    onlyOnCanBeOpen: boolean = true;
    constructor() {
        this.detailsOpenCloseButtons = document.querySelectorAll(
            ".question,.teaser, .basic-data-button"
        );

    }

    registerEventListeners() {
        this.detailsOpenCloseButtons.forEach((detailsOpenCloseButton, detailsOpenCloseButtonIndex) => {

            detailsOpenCloseButton.addEventListener("click", (evt) => {

                let target: HTMLElement = detailsOpenCloseButton.parentElement;// für faq und teaser
                if (detailsOpenCloseButton.dataset.target  != null) {
                    target = document.querySelector(detailsOpenCloseButton.dataset.target);
                    // für basics
                }

                if (target.classList.contains("closed")) {
                    target.classList.remove("closed");
                    detailsOpenCloseButton.classList.remove("closed");
                    if (target.classList.contains("close-others-if-open")) {
                        this.detailsOpenCloseButtons.forEach((elm, index) => {
                            if (elm !== detailsOpenCloseButton) {
                                elm.parentElement.classList.add("closed");
                                elm.parentElement.classList.remove("open");

                            }
                        })
                    }

                    target.classList.add("open");
                    detailsOpenCloseButton.classList.remove("open");
                } else {
                    target.classList.remove("open");
                    target.classList.add("closed");
                    detailsOpenCloseButton.classList.remove("open");
                    detailsOpenCloseButton.classList.add("closed");
                }
            });

        });


    }
}
