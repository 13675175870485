export class GlossaryHandler {
    glossaryWrapper: HTMLElement;
    glossaryElements: NodeListOf<HTMLElement>;
    glossaryLinks: NodeListOf<HTMLElement>;
docClickListener: EventListener;

    constructor() {
        this.glossaryWrapper = document.querySelector(".glossary-wrapper");
        if (this.glossaryWrapper !== null) {
            this.glossaryElements =
                this.glossaryWrapper.querySelectorAll(".glossary-element");
            this.glossaryLinks = document.querySelectorAll('a[href^="#glossary"');
            this.glossaryLinks.forEach((glossaryLink: HTMLElement) => {
                const glossaryLinkHref = glossaryLink.getAttribute("href");
                const glossaryName = glossaryLinkHref.substring(glossaryLinkHref.indexOf(":") + 1);
                glossaryLink.dataset.linkTarget = `.glossary-${glossaryName}`;
                const glossaryElement: HTMLElement = this.glossaryWrapper.querySelector(`.glossary-${glossaryName}`
                );
                if (glossaryElement != undefined) {
                    glossaryLink.classList.add('glossary-symbol-color-' + glossaryElement.dataset.symbolColor);

                }
            });
        }
    }

    
    registerEventListeners() {
        this.docClickListener = this.closeGlossaries.bind(this); //noch nicht geadded...
        if (this.glossaryWrapper !== null) {
            this.glossaryLinks.forEach((glossaryLink: HTMLElement) => {
                //               
                const glossaryName = glossaryLink.dataset.linkTarget;
                const glossaryElement: HTMLElement =
                    this.glossaryWrapper.querySelector(glossaryName)

                const glossaryTriangle: HTMLElement =
                    glossaryElement.querySelector(".triangle");
                if (glossaryElement != undefined) {
                    glossaryElement.addEventListener("click", (evt) => {
                        evt.stopPropagation();
                    });
                    glossaryLink.addEventListener("click", (evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                       
                        this.closeOtherGlossaries(glossaryElement);
                        document.addEventListener("click", this.docClickListener, false);
                        // keep glossary within confines of viewport


                        if (
                            glossaryLink.querySelector(".glossary-element") ==
                            undefined
                        ) {
                            glossaryLink.appendChild(glossaryElement);
                            if (
                                glossaryLink.querySelector("audio") != undefined
                            ) {
                                glossaryLink.querySelector("audio").play();
                            }
                            if (
                                glossaryLink.querySelector("video") != undefined
                            ) {
                                glossaryLink.querySelector("video").play();
                            }
                            if (
                                glossaryLink.querySelector("iframe") !=
                                undefined &&
                                glossaryLink.querySelector(
                                    ".placeholder.hidden"
                                ) != undefined
                            ) {
                                glossaryElement
                                    .querySelector("iframe")
                                    .setAttribute(
                                        "src",
                                        glossaryElement
                                            .querySelector("iframe")
                                            .getAttribute("data-src")
                                    );
                            }
                            glossaryLink.blur();
                            if (glossaryElement.getBoundingClientRect().x < 0) {
                                glossaryElement.style.transform = `translateX(${8 - glossaryLink.getBoundingClientRect().x
                                    }px)`;
                                glossaryElement.style.left = "0";
                                glossaryTriangle.style.left = `${glossaryLink.getBoundingClientRect().x -
                                    8 +
                                    0.5 *
                                    glossaryLink.getBoundingClientRect()
                                        .width
                                    }px`;
                            } else if (
                                glossaryElement.getBoundingClientRect().x +
                                glossaryElement.getBoundingClientRect()
                                    .width >
                                window.innerWidth
                            ) {
                                glossaryElement.style.transform = `translateX(${window.innerWidth -
                                    glossaryLink.getBoundingClientRect().x -
                                    glossaryLink.getBoundingClientRect().width -
                                    8
                                    }px)`;
                                glossaryElement.style.right = "0";
                                glossaryElement.style.left = "unset";
                                glossaryTriangle.style.left = "unset";
                                glossaryTriangle.style.right = `${window.innerWidth -
                                    glossaryLink.getBoundingClientRect().x -
                                    0.5 *
                                    glossaryLink.getBoundingClientRect()
                                        .width -
                                    8
                                    }px`;
                                glossaryTriangle.style.transform =
                                    "translateX(50%) rotate(45deg)";
                            }
                        }
                    });
                }
            });
            this.glossaryElements.forEach((glossaryElement: HTMLElement) => {
                const glossaryElementCloseButton =
                    glossaryElement.querySelector(
                        ".glossary-element-close-button"
                    );
                glossaryElementCloseButton.addEventListener("click", () => {
                    this.closeGlossary(glossaryElement);
                });
            });
            
        }
    }

    closeGlossary(glossaryElement: HTMLElement) {
        // need timeout because otherwise the close button would be removed while its click event listener runs -> error
        window.setTimeout(() => {
            glossaryElement.querySelector("audio")?.pause();
            glossaryElement.querySelector("video")?.pause();
            glossaryElement.querySelector("iframe")?.removeAttribute("src");
            this.glossaryWrapper.appendChild(glossaryElement);
        }, 0);
    }
    closeGlossaries() {
         this.glossaryElements.forEach((glossaryElement: HTMLElement) => {
            this.closeGlossary(glossaryElement);
        });
         document.removeEventListener("click", this.docClickListener, false);
    }
    closeOtherGlossaries(glossaryElementToKeepOpen: HTMLElement) {
        this.glossaryElements.forEach((glossaryElement: HTMLElement) => {
            if (glossaryElementToKeepOpen != glossaryElement) {
                this.closeGlossary(glossaryElement);
            }
        });
    }
}
