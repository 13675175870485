export class NavigationHandler {
    navigationOpenCloseButton: HTMLElement;
    siteNavigation: HTMLElement;
    siteNavigationInner: HTMLElement;
    constructor() {
        this.navigationOpenCloseButton = document.querySelector(
            ".navigation-open-close-button"
        );
        this.siteNavigation = document.querySelector(".site-navigation");
        this.siteNavigationInner = this.siteNavigation.querySelector(
            ".site-navigation-inner"
        );
    }

    registerEventListeners() {
        this.navigationOpenCloseButton.addEventListener("click", () => {
            this.toggleNavigationOpened();
        });
        this.siteNavigationInner.addEventListener("click", (evt) => {
            evt.stopPropagation();
        });
        this.siteNavigation.addEventListener("click", () => {
            this.toggleNavigationOpened();
        });
    }

    toggleNavigationOpened() {
        if (this.navigationOpenCloseButton.className.includes("opened")) {
            this.navigationOpenCloseButton.className =
                this.navigationOpenCloseButton.className
                    .replace("opened", "")
                    .trim();
            this.siteNavigation.className = this.siteNavigation.className
                .replace("opened", "")
                .trim();
        } else {
            this.navigationOpenCloseButton.className += " opened";
            this.siteNavigation.className += " opened";
        }
    }
}
