export class Toc {
    body: HTMLElement
    topBarOverlay: HTMLElement;
    wrapper: HTMLElement;
    toc: HTMLElement;
    ul: HTMLElement;
    anchors: NodeListOf<HTMLElement>;
    links: NodeListOf<HTMLElement>;
    linklist: HTMLElement = createElement("ul");
    tocLinkAutosetIsActive: boolean = true;
    autoSettedLinkIndex: number = -1;
    visibleLinkIndex: number = -1;
    linksWidth: number
    tocWidth: number;
    scrollPos: number;
    currentScrollDirection: number;
    mouseOverTocFlag: boolean = false;

    constructor() {
        this.body = document.body;
        this.topBarOverlay = document.querySelector(".top-bar-overlay");
        this.toc = document.querySelector("#toc");

        this.wrapper = this.toc.parentElement;
        this.anchors = document.querySelectorAll(".is-in-toc");

    }

    registerEventListeners() {
        this.buildToc();
        if (this.links.length > 0) {
            this.toc.classList.remove("unvisible");


            if (!isTouchDevice()) {
                window.addEventListener('resize', this.resize.bind(this), false);
                this.topBarOverlay.addEventListener('mouseenter', this.topBarMouseEnterHandler.bind(this), false);
                this.topBarOverlay.addEventListener('mouseleave', this.topBarMouseLeaveHandler.bind(this), false);
            }

            const onScrollStop = callback => {
                let isScrolling;
                window.addEventListener(
                    'scroll',
                    function (e: MouseEvent) {
                        window.clearTimeout(isScrolling);
                        isScrolling = setTimeout(() => {

                            callback();
                        }, 250);
                    },
                    false
                );
            };
            //
            onScrollStop(() => {
                this.trackScrollDirection();
                this.updateVisibility();
            });
            //
        } else {
            this.toc.classList.add("unvisible");

        }
        this.resize();
    }

    buildToc() {
        let linkList: HTMLElement = createElement("ul");
        this.anchors.forEach((anchor: HTMLElement, index) => {

            var id: string = this.fixedEncodeURI(anchor.innerText)
//            var anchorTarget: HTMLElemen            t = createElement('a');
//             anchorTarget.            setAttribute("id", id);
//             anchorTarget.prepend(anchorLink);


            var anchorLink: HTMLElement = createElement("a");
            //            anchorLink.setAttribute("name", id);
            anchorLink.setAttribute("id", id);
            anchorLink.classList.add("anchor-target");


            anchor.prepend(anchorLink);
            var li: HTMLElement = createElement("li");
            if (li.getAttribute('name') != "" || li.getAttribute('id') != "")
                var a: HTMLElement = createElement("a")
            if (anchor.tagName == "IMG") {
                a.innerText = anchor.getAttribute("alt");
            } else a.innerText = anchor.innerText;

            a.setAttribute("href", "#" + id)
            //            a.classList.add("anchor-link");

            li.append(a);
            li.classList.add("anchor");
            linkList.append(li);
        });
        this.toc.append(linkList);
        this.ul = this.toc.querySelector("ul");
        this.links = this.toc.querySelectorAll(".anchor");

    }
    fixedEncodeURI(str: string) {
        return encodeURI(str).replace(/%5B/g, '[').replace(/%5D/g, ']');
    }
    isElementInViewport(element: HTMLElement) {
        var rect: DOMRect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }



    updateVisibility() {

        var visibleHasChanged: boolean = false;

        for (var i = 0; i < this.anchors.length; i++) {
            if (this.isElementInViewport(this.anchors[i])) {
                if (!visibleHasChanged) {
                    this.setVisibility(i, true);
                    visibleHasChanged = true;
                    this.visibleLinkIndex = i;


                    if (this.tocLinkAutosetIsActive) {
                        this.tocLinkAutosetIsActive = false;
                        this.setVisibility(this.autoSettedLinkIndex, false);
                        this.autoSettedLinkIndex = -1;
                    }
                }
            }
            else {
                if (!this.tocLinkAutosetIsActive || (this.tocLinkAutosetIsActive && this.autoSettedLinkIndex !== i)) {
                    this.setVisibility(i, false);
                }
            }
        }

        if (!visibleHasChanged && !this.tocLinkAutosetIsActive) {
            if (this.currentScrollDirection === -1 && this.visibleLinkIndex > 0) {
                this.visibleLinkIndex--;
            }
            this.autoSettedLinkIndex = this.visibleLinkIndex;
            this.tocLinkAutosetIsActive = true;
            this.setVisibility(this.autoSettedLinkIndex, true);
        }
        if (visibleHasChanged) this.moveBar();

    }

    moveBar() {
        if (this.topBarOverlay.offsetWidth >= this.linksWidth) return
        //        console.log("moveBar: offsttLeft visible Link1: " + this.links[this.visibleLinkIndex].offsetLeft)
        this.topBarOverlay.scrollTo({
            left: this.links[this.visibleLinkIndex].offsetLeft,
            behavior: "smooth",
            top: 0
        })

    }


    resize() {
        this.linksWidth = this.tocWidth = this.getLinksWidth();
        this.toc.style.width = this.getLinksWidth() + "px";
    }


    topBarMouseEnterHandler(e: Event) {
        this.links.forEach((link: HTMLElement) => {
            link.classList.add("up");


        })
        this.mouseOverTocFlag = true
        if (this.topBarOverlay.offsetWidth < this.linksWidth) {
            this.toc.addEventListener('mousemove', this.moveMouseOverTopBarHandler.bind(this))
        }
    }

    mouseOverTopBarSpeed: number = 1.1;
    rel: number;
    divPos: number;
    moveMouseOverTopBarHandler(e: MouseEvent) {
        let targ: HTMLElement = e.currentTarget as HTMLElement;
        targ = targ.parentElement;
        let divPos = e.clientX - targ.offsetLeft;
        this.rel = .5 + divPos / targ.offsetWidth;
        //        console.log(this.rel)
        this.topBarOverlay.scrollTo({
            left: (this.linksWidth - targ.offsetWidth ) * this.rel * this.mouseOverTopBarSpeed,
        });
    }

    topBarMouseLeaveHandler(e: Event) {
        this.links.forEach((link: HTMLElement) => {
            link.classList.remove("up")
        })
        this.toc.removeEventListener('mousemove', this.moveMouseOverTopBarHandler.bind(this))
    }



    setVisibility(index: number, onOff: boolean) {
        if (index === -1) return;
        if (onOff) {
            this.anchors[index].classList.add("visible");
            this.links[index].classList.add("visible");
        } else {
            this.anchors[index].classList.remove("visible");
            this.links[index].classList.remove("visible");
        }
    }

    trackScrollDirection() {
        // console.log("trackScrollDirection: " + this.currentScrollDirection);

        let top: number = this.body.getBoundingClientRect().top;
        if (top > this.scrollPos)
            this.currentScrollDirection = -1;//Up
        else
            this.currentScrollDirection = 1;//Down
        //speichern
        this.scrollPos = top;
    }

    getLinksWidth(): number {

        if (this.links.length === 0) return 0;
//        console.log("LinksWidth: " + (this.links[this.links.length - 1].offsetLeft + this.links[this.links.length - 1].offsetWidth + 10))
        return this.links[this.links.length - 1].offsetLeft + this.links[this.links.length - 1].offsetWidth + 10;


    }

}
//WORKAROUND f&uuml;r netbeans Problem mit "createElement" // AL  :-( 
function createElement(tag: string) {
    return document.createElement(tag);
}

function isTouchDevice(): boolean {
    //    return false;
    return ('ontouchstart' in window);
}