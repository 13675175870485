import {LanguageSelectHandler} from "./LanguageSelectHandler";

export class TopbarHandler {
    topBar: HTMLElement;
    topBarSearchWrapper: HTMLElement;
    topBarSearchButton: HTMLElement;
    topBarSearchInput: HTMLElement;
    topBarOverlay: HTMLElement;

    docClickListener: EventListener;
    constructor() {
        this.topBar = document.querySelector(".top-bar");
        this.topBarSearchWrapper = this.topBar.querySelector(".top-bar-search");
        this.topBarSearchButton =
            this.topBarSearchWrapper.querySelector(".search-button");
        this.topBarSearchInput = this.topBarSearchWrapper.querySelector(
            ".search-input-wrapper input"
        );
        this.topBarOverlay = document.querySelector(".top-bar-overlay");
    }
    registerEventListeners() {
        this.topBarSearchButton.addEventListener("click", (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            this.toggleTopBarSearchOpened();

        });
        this.topBarSearchInput.addEventListener("click", (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
        });
        this.docClickListener = this.closeTopBarByDocumentClick.bind(this); //noch nicht geadded...

        document.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                this.topBar.classList.add("minimized");
                this.topBarOverlay.classList.add("minimized");
                if (isTouchDevice()) {
                    //                    this.topBar.querySelector("#toc").classList.remove("unvisible");
                }
            } else {
                this.topBar.classList.remove("minimized")
                this.topBarOverlay.classList.remove("minimized");
                if (isTouchDevice()) {
                    this.topBar.querySelector("#toc").classList.add("unvisible");
                }

            }
        });

    }

    closeTopBarByDocumentClick(e: MouseEvent) {
 
        let that = e.target as HTMLElement;
        if (!that.classList.contains('search-checkbox') && !that.classList.contains('search-checkbox-label'))
            this.closeTopBarSearch();
    }

    toggleTopBarSearchOpened() {
        if (this.topBarSearchWrapper.classList.contains("opened")) {
            this.closeTopBarSearch();
            this.topBarOverlay.classList.remove("closed");
        } else {
            this.openTopBarSearch();
            this.topBarOverlay.classList.add("closed");
        }
    }
    openTopBarSearch() {
        this.topBarSearchWrapper.classList.add("opened");
        document.addEventListener("click", this.docClickListener, false);
        this.topBarSearchInput.focus();
        let languageSelectHandler: LanguageSelectHandler =
            new LanguageSelectHandler();
        languageSelectHandler.closeAllLanguageSelects();
    }
    public closeTopBarSearch() {
        document.removeEventListener("click", this.docClickListener, false);
        this.topBarSearchWrapper.classList.remove("opened");

    }
}
function isTouchDevice(): boolean {
    return ('ontouchstart' in window);
}