import { TopbarHandler } from "./TopbarHandler";

export class LanguageSelectHandler {
    languageSelects: NodeListOf<HTMLElement>;
    languageSelectButtons: NodeListOf<HTMLElement>;

    constructor() {
        this.languageSelects = document.querySelectorAll(".language-select");
        this.languageSelectButtons = document.querySelectorAll(
            ".language-select-button"
        );
    }
    registerEventListeners() {
        this.languageSelectButtons.forEach((button, index) => {
            button.addEventListener("click", (evt) => {
                console.log("lang select clicked");
                evt.stopPropagation();
                evt.preventDefault();
                this.toggleLanguageSelectOpened(index);
                this.languageSelectButtons.item(index).blur();
            });
        });
        document.addEventListener("click", (evt) => {
            this.languageSelects.forEach((_, index) => {
                this.closeLanguageSelect(index);
            });
        });
    }
    toggleLanguageSelectOpened(index: number) {
        const languageSelect = this.languageSelects.item(index);
        if (languageSelect.className.includes("opened")) {
            this.closeLanguageSelect(index);
        } else {
            this.openLanguageSelect(index);
        }
    }
    openLanguageSelect(index: number) {
        const languageSelect = this.languageSelects.item(index);
        if (!languageSelect.className.includes("opened")) {
            languageSelect.className += " opened";
        }
        let topbarHandler: TopbarHandler = new TopbarHandler();
        topbarHandler.closeTopBarSearch();
    }
    closeLanguageSelect(index: number) {
        const languageSelect = this.languageSelects.item(index);
        languageSelect.className = languageSelect.className
            .replace("opened", "")
            .trim();
    }
    closeAllLanguageSelects() {
        this.languageSelects.forEach((_, index) => {
            this.closeLanguageSelect(index);
        });
    }
}
